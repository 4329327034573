import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { Connection, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from "react-toastify";

const Home: FC = () => {
    const { connected, publicKey, sendTransaction } = useWallet();
    const { setVisible } = useWalletModal();
    const [solAmount, setSolAmount] = useState(0);
    const [validSol, setValidSol] = useState(false);
    const [loading, setLoading] = useState(false);

    const connection = new Connection(process.env.REACT_APP_QUICKNODE_ENDPOINT as string);

    useEffect(() => {
        if (!isNaN(solAmount)) {
            setValidSol(false);
        } else {
            setValidSol(true);
        }
    }, [solAmount]);

    const buyNow = async () => {
        try {
            if (!publicKey) {
                toast.error('Wallet not connected!');
                return;
            }
            setLoading(true);

            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: new PublicKey("Cqd2QZxEogvswWJuhrJcLxz9LrTXq4pPK6azBygwc5BX"),
                    lamports: (solAmount * 1000000000),
                })
            );

            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight }
            } = await connection.getLatestBlockhashAndContext();

            const signature = await sendTransaction(transaction, connection, { minContextSlot });

            const confirmation = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });
            if (confirmation.value) {
                await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/save-transaction`, {
                    from_address: publicKey.toString(),
                    to_address: "Cqd2QZxEogvswWJuhrJcLxz9LrTXq4pPK6azBygwc5BX",
                    transaction_id: signature,
                    amount: solAmount,
                });
                toast.success("Purchased Successfull! Token will be soon airdropped to your wallet!");
            }

            setLoading(false);
        } catch (err) {
            toast.error("Something went wrong! Please try again!");
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.getElementById('navbar');
            if (navbar) {
                if (window.scrollY > 50) {
                    navbar.classList.add('scrolled');
                } else {
                    navbar.classList.remove('scrolled');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top navbarCustom" id="navbar">
                <div className="container">
                    <a className="navbar-brand flex" href="#none">
                        <img src="images/1Klie.jpeg" className="logo img-fluid" alt="none" />
                        <div>KOALIE</div>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#HowToBuy">How to Buy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#FAQ">FAQ</a>
                            </li>
                        </ul>
                        <a href="https://twitter.com/koaliecoin" target="_blank" rel="noreferrer" className="headerSocial">
                            <img src="images/x.png" className="img-fluid socialIcon" alt="none" />
                        </a>
                        <a href="https://t.me/Koaliecoin" target="_blank" rel="noreferrer" className="headerSocial">
                            <img src="images/telegram.png" className="img-fluid socialIcon" alt="none" />
                        </a>
                    </div>
                </div>
            </nav>

            <div className="mainBanner">
                <div className="container">
                    <div className="row flex-wrap align-items-end justify-content-between heightFull">
                        <div className="col-12 col-lg-6 col-xl-6 col-xxl-5">
                            <div className="byuBox">
                                <div className="font30 fontBold colorTheme text-center mb-5">Best chance to buy before launch!</div>
                                <div className="font30 fontBold colorTheme text-center mb-3">1 Solana = 25,000 $Koalie</div>
                                <div className="mb-5">
                                    <form className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
                                        <InputGroup className="mb-3 w-75">
                                            <Form.Control
                                                placeholder="Enter Amount"
                                                aria-label="Enter Amount"
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => setSolAmount(Number(e.target.value))}
                                                defaultValue="0"
                                                isInvalid={validSol}
                                                className="bg-dark text-white"
                                            />
                                            <InputGroup.Text className="bg-dark text-white" id="basic-addon2">SOL</InputGroup.Text>
                                        </InputGroup>
                                        <Button
                                            variant="dark"
                                            disabled={loading}
                                            onClick={() => {
                                                if (connected) {
                                                    buyNow();
                                                } else {
                                                    setVisible(true);
                                                }
                                            }}
                                            className="w-25">{loading ? 'Loading...' : connected ? 'Buy Now!*' : 'Connect'}</Button>
                                    </form>
                                </div>
                                <div className="font30 fontBold colorTheme text-center textWrap">
                                    *Can also send the Sol to Cqd2QZxEogvswWJuhrJcLxz9LrTXq4pPK6azBygwc5BX from a decentralized wallet
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6 col-xxl-6">
                            <img src="images/none.png" className="img-fluid" alt="none" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="aboutusSection">
                <div className="container-fluid">
                    <div className="row flex-wrap align-items-start justify-content-between">
                        <div className="col-12 col-lg-12 col-xl-auto col-xxl-auto">
                            <img src="images/aboutUs.png" className="img-fluid aboutImg" alt="none" />
                        </div>
                        <div className="col-12 col-lg-12 col-xl col-xxl aboutSpace">
                            <h1>About</h1>
                            <div className="font38">Koalie Story:</div>
                            <div className="font30">
                                The news of Koalie arrival spread like wild-fire in
                                the heart of jungle and there’s been a bash by the
                                other animals who welcomed “KO” into the fam as
                                a classic demen move. As, everyone knew it’s the
                                journey from leafy lounges to crypto richies on the
                                moon! The celebrations continued as everyone
                                screaming loud to the moon and tearing up the
                                dance floor causing every other solana animal
                                squad to wake up and grin in the silence that -
                                “ This legend is about to burst and roar!”
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tokenomicsSection">
                <div className="container">
                    <div className="box">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-7 col-xxl p-5">
                                <h1 className="text-center mb-5">Tokenomics:</h1>
                                <div className="row font26 bodrBottom py-3">
                                    <div className="col-6">Name:</div>
                                    <div className="col-6">Koalie</div>
                                </div>
                                <div className="row font26 bodrBottom py-3">
                                    <div className="col-6">Symbol:</div>
                                    <div className="col-6">$KLIE</div>
                                </div>
                                <div className="row font26 bodrBottom py-3">
                                    <div className="col-6">Supply:</div>
                                    <div className="col-6">500 Million</div>
                                </div>
                                <div className="row font26 bodrBottom py-3">
                                    <div className="col-6">Pre-Sale:</div>
                                    <div className="col-6">20%</div>
                                </div>
                                <div className="row font26 bodrBottom py-3">
                                    <div className="col-6">Contract Address:</div>
                                    <div className="col-6">Lorem Ipsum</div>
                                </div>

                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 col-xxl-auto tokenomicsImg">
                                <img src="images/tokenomicsImg.png" className="img-fluid" alt="none" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tokenDistribution">
                <div className="container">
                    <h1 className="text-center mb-5">Token Distribution</h1>
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-7 col-xl-6 col-xxl-6">
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox liquidity me-3"></div>
                                <div className="font26 fontBold">Liquidity - 60%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox presale me-3"></div>
                                <div className="font26 fontBold">Pre - sale - 20%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox airdrop1 me-3"></div>
                                <div className="font26 fontBold">Airdrops 1 - 3%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox airdrop2 me-3"></div>
                                <div className="font26 fontBold">Airdrops 2 - 2%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox taxes me-3"></div>
                                <div className="font26 fontBold">Taxes - 0%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox marketing me-3"></div>
                                <div className="font26 fontBold">Marketing - 5%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox team me-3"></div>
                                <div className="font26 fontBold">Team - 5%</div>
                            </div>
                            <div className="d-flex align-items-center mb-3 w-100%">
                                <div className="tokenBox community me-3"></div>
                                <div className="font26 fontBold">Surprise To The Community - 5%</div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xl-6 col-xxl-6 text-end">
                            <img src="images/TokenDistributionImg.png" className="img-fluid" alt="none" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="ourPartners">
                <div className="container">
                    <h1 className="text-center mb-5">Our Partners</h1>
                    <div className="row justify-content-between">
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/BitMart.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/ave.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/BYBIT.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/coin_gecko.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/coin_market_cap.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/dex_screener.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/DEX_VIEW.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/PINKSALE.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                        <div className="col-6 col-lg-3 mb-4">
                            <div className="logoBox text-center"><img src="images/raydium.jpg" className="img-fluid" alt="none" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="howToBuySection" id="HowToBuy">
                <div className="container">
                    <div className="row flex-wrap align-items-center justify-content-between">
                        <div className="col-12 col-lg-12 col-xl-7 col-xxl mb-5 mb-xl-0">
                            <h1>HOW TO BUY KLIE</h1>
                            <div className="font30" style={{ wordWrap: "break-word" }}>
                                Press ‘Buy Now’ above and connect wallet
                                Enter amount of SOL to swap for $Koalie
                                Hit ‘Buy Now’ again and confirm transaction<br /><br />
                                If you can’t connect your wallet,just send SOL to Cqd2QZxEogvswWJuhrJcLxz9LrTXq4pPK6azBygwc5BX
                                & tokens will be airdropped to the sending wallet.
                                NB: Don’t use a CEX to send SOL! Use your own wallet.
                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-5 col-xxl-auto">
                            <img src="images/howtoby.png" className="img-fluid" alt="none" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="faqSection" id="FAQ">
                <div className="container">
                    <h1 className="text-center mb-5">FAQ's</h1>
                    <div className="row justify-content-end">
                        <div className="col-12 col-lg-6 col-xl-4 mb-4">
                            <div className="faqBox">
                                <div className="d-block">
                                    <h2>01</h2>
                                </div>
                                <div className="font30Gray mt-2">
                                    Expert instruction
                                    The gradual accumulation of
                                    information about atomic and small-scale behaviour...
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4 mb-4">
                            <div className="faqBox">
                                <div className="d-block">
                                    <h2>02</h2>
                                </div>
                                <div className="font30Gray mt-2">
                                    Keep an eye on your wallet for the arrival of
                                    $ Koalie. Follow our twitter for further announcements.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="container">
                    <div className="text-center mb-5">
                        <img src="images/1Klie.jpeg" className="logoFooter" alt="none" />
                        <div className="text-center font26White">KOALIE</div>
                    </div>
                    <div className="text-center footerLine mb-5">
                        "In the jungle race, where Koalie soars and the community roars!"
                    </div>
                    <div className="text-center font26White mb-5">
                        Follow us on<br />
                        <div className="d-flex justify-content-center">
                            <a href="https://twitter.com/koaliecoin" target="_blank" rel="noreferrer" className="headerSocial">
                                <img src="images/x.png" className="img-fluid socialIcon" alt="none" />
                            </a>
                            <a href="https://t.me/Koaliecoin" rel="noreferrer" target="_blank" className="headerSocial">
                                <img src="images/telegram.png" className="img-fluid socialIcon" alt="none" />
                            </a>
                        </div>
                    </div>
                    <div className="text-center font26White">
                        Copyright: &copy; 2024 Koalie, All Rights Reserved.
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;