import { createRoot } from 'react-dom/client';
import App from './App';
import Wallet from './WalletProvider';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import "./media.css";
import "react-toastify/dist/ReactToastify.css";


const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Wallet>
        <ToastContainer />
        <App />
    </Wallet>
);